@charset "utf-8";
@import "settings";
//@import "assets/vendor/zurb/foundation/assets/foundation";
@import "assets/vendor/zurb/foundation/scss/foundation";
// @import "assets/components/motion-ui/motion-ui";
@import "aktiv-base";
@import "slick";
@import "slick-lightbox";
// Or selectively include components
// Global styles //
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
// Grids (choose one)
//@include foundation-xy-grid-classes;
// @include foundation-grid;
@include foundation-flex-grid;
// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
// @include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;
// Containers
@include foundation-accordion;
@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;
// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;
// Helpers
//@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;
// Motion UI
//@include motion-ui-transitions;
//@include motion-ui-animations;
// FOR IE 10
@-ms-viewport {
  width: device-width;
}
// FONTS
//////////////////////////////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;600;800&family=Merriweather:wght@300;400&display=swap');
// MIXINS
//////////////////////////////////////////////////////////////////////////
@function f-header-size($breakpoint, $size) {
  @return rem-calc(map-get(map-get(map-get($header-styles, $breakpoint), $size), "font-size") * 1px);
}
@mixin header-size($type) {
  font-size: rem-calc(map-get(map-get(map-get($header-styles, "small"), $type), "font-size") * 1px);
  @include breakpoint(medium) {
    font-size: rem-calc(map-get(map-get(map-get($header-styles, "medium"), $type), "font-size") * 1px);
  }
}
@mixin stak {
  // defaults;
  padding: 20px 0;
  p {
    font-size: 1em;
    &.lead {
      font-size: 100%;
      line-height: 1.6;
    }
  }
  @include breakpoint(medium) {
    // enhancements;
    padding: 41px 0;
  }
  @include breakpoint(large) {
    // enhancements;
    padding: 60px 0;
    p {
      font-size: 1em;
      &.lead {
        font-size: 125%;
        line-height: 1.65;
      }
    }
  }
}
@mixin hero-slide {
  // defaults;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover !important;
  z-index: 0;
  overflow: hidden;
  background-position: center center;
  backface-visibility: hidden;
  align-items: center;
  display: flex;
  @include breakpoint(medium) {
    // enhancements;
    // height: 400px;
  }
  @include breakpoint(large) {
    // enhancements;
    height: 550px;
  }
  @include breakpoint(xlarge) {
    // enhancements;
    height: 600px;
  }
}
@mixin no-bullet {
  list-style-type: none;
  margin: 0;
}
@mixin stak-pic {
  @include stak;
  display: table;
  width: 100%;
  min-height: 300px;
  .row {
    display: table-row;
    width: 100%;
  }
  .columns {
    display: table-cell;
    float: none;
    vertical-align: middle;
    text-align: center;
  }
  .column:last-child:not(:first-child),
  .columns:last-child:not(:first-child) {
    float: none;
  }
  .vertical-center {
    vertical-align: middle;
  }
  h2 {
    //color: $white;
    line-height: initial;
    span {
      background-color: rgba($body-font-color, 0.8);
      padding: 0 0.25em;
      margin: 0;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
    strong {
      color: $black;
      display: inline-block;
    }
  }
  h3,
  li,
  p,
  strong {
    //color: $white;
  }
  @include breakpoint(large) {
    background-attachment: fixed;
  }
}
@mixin fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  left: 0;
  &.expanded:not(.top-bar) {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  &.expanded:not(.top-bar) .title-area {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  &.expanded:not(.top-bar) .top-bar-section {
    margin-top: 5rem;
    z-index: 98;
  }
}
@mixin radius {
  border-radius: 4px;
}
.foo {
  @include grid-row;
  .bar {
    @include grid-column(12);
  }
}
@media screen and #{breakpoint(medium)} {
  .foo {
    @include grid-row;
    .bar {
      @include grid-column(4);
    }
  }
}
@mixin overlay {
  content: "";
  //background: rgba($gray, .5);
  background: linear-gradient(45deg, rgba($gray, .75) 0%, rgba($secondary-color, .05) 50%);
  mask: linear-gradient(45deg, rgba($gray, .5) 10%, transparent);
  backdrop-filter: blur(20px);
  position: absolute;
  //mix-blend-mode: normal;
  opacity: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  height: 100%;
  width: 100%;
}
@mixin overlay-home {
  content: "";
  background: rgba($gray, .5);
  background: linear-gradient(135deg, rgba($primary-color, .75) 0%, rgba($secondary-color, .35) 100%);
  position: absolute;
  mix-blend-mode: normal;
  opacity: 1;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  height: 100%;
  width: 100%;
}
@mixin overlay-solid {
  content: "";
  //background: rgba($gray, .5);
  position: absolute;
  mix-blend-mode: multiply;
  opacity: .5;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
}
@mixin box-shadow {
  box-shadow: 0 8px 10px 0 rgba($gray, 0.5);
}
// DEFAULTS (* see _settings.scss for FOUNDATION OVERRIDES)
//////////////////////////////////////////////////////////////////////////
.page-content {
  background-color: $white;
  display: inline-block;
  width: 100%;
  //background-image: url("../images/bgd-wallpaper.jpg");
  //background-repeat: repeat;
  //background-attachment: fixed;
  &:before {
    content: "";
  }
}
$logo-small: 200px;
$logo-medium: 300px;
$logo-large: 400px;
address {
  font-style: normal;
  margin-bottom: 2em;
  strong {
    color: $black;
  }
}
.columns {
  position: relative;
}
.fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}
$off-canvas-width: 60%;
.row.expanded {
  max-width: none;
  > .columns {
    padding: 0;
  }
}
:focus {
  outline: none;
}
a.has-tip {
  border-bottom: none;
  color: $primary-color;
  cursor: pointer;
  font-weight: normal;
}
.slick-arrow {
  z-index: 1;
}
.arrow-style {
  @include header-size(h5);
  color: $white !important;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  transition-timing-function: linear;
  transition: all 0.5s ease;
  margin: 0 auto 1em !important;
  position: relative;
  min-width: 100px;
  min-height: 40px;
  background-color: transparent;
  border: 1px solid $white;
  &:hover {
    background-color: transparent;
    border: 1px solid $primary-color;
  }
  span {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  span:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
  }
  &:before {
    content: '';
    background: transparent url("../images/arrow-line.svg") center center no-repeat;
    background-size: 60%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition-timing-function: linear;
    transform: translateZ(0) scale(1) translate(0%, 0);
    transition: all 0.2s ease-in-out 0s;
  }
  &:hover:before {
    //animation: arrow-button .4s ease-in forwards;
    animation-name: arrow-button;
    animation-timing-function: cubic-bezier(.17, .67, .83, .67);
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }
}
ul.link-list {
  list-style: none;
  margin: 0 auto;
  li:after {
    content: "\203A \20";
    padding-left: 0.25em;
  }
  li:hover:after {
    color: $warning-color;
  }
  a {
    display: inline-block;
    &:after {
      content: '';
      display: block;
      height: 3px;
      width: 0;
      margin: 0.25em 0 0;
      background: transparent;
      transition: width 0.25s ease, background-color 0.25s ease;
    }
    &:hover:after {
      width: 100%;
      background: $warning-color;
    }
  }
}
ul.row {
  list-style: none;
  li {
    margin: 0.5em 0;
    line-height: 1.225em;
  }
}
.stak-pic {
  @include stak-pic;
}
// prevent mobile no-scroll
.slick-slider {
  touch-action: auto;
  -ms-touch-action: auto;
}
// WordPress alignment classes
.aligncenter {
  display: flex;
  flex: 1;
  margin: auto;
}
.alignright {
  float: right;
  margin-left: auto;
}
.alignleft {
  float: left;
  margin-right: auto;
}
.button.round {
  border-radius: 1000px;
}
picture.round, figure.round {
  transition: transform .3s ease;
  object-fit: cover;
  object-position: center;
  display: block;
  justify-content: center;
  img {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    align-items: center;
    //aspect-ratio: 1 !important;
    display: flex;
    justify-content: center;
  }
}
.accordion {
  margin: 2em auto;
  .accordion-item {
    .accordion-title {
      border-bottom: 1px solid rgba($light-gray, .25);
      font-weight: bold;
      &:hover, &:focus {
        color: $primary-color;
        background-color: lighten($primary-color, 50%);
        &::before {
          color: lighten($secondary-color, 10%);
        }
      }
      &::before {
        color: $secondary-color;
      }
    }
    &.is-active {
      .accordion-title {
        background-color: $primary-color;
        color: $white;
        &::before {
          color: lighten($primary-color, 30%);
        }
      }
    }
    @include breakpoint(medium) {
      .accordion-title {
        padding: 2em 1em;
      }
    }
  }
}
// GLOBALS (CUSTOM GLOBALS)
//////////////////////////////////////////////////////////////////////////
// color palette
.blue {
  color: $blue;
}
.green {
  color: $green;
}
.ocher {
  color: $ocher;
}
.red {
  color: $red;
}
.purple {
  color: $purple;
}
.gray {
  color: $gray;
}
.light-gray {
  color: $light-gray;
}
.medium-gray {
  color: $medium-gray;
}
.dark-gray {
  color: $dark-gray;
}
//background colors palette
.bgd-blue, .stak-blue {
  background-color: $blue;
  .subtitle {
    color: darken($blue, 20%);
  }
}
.bgd-green, .stak-green {
  background-color: $green;
  .subtitle {
    color: darken($green, 20%);
  }
}
.bgd-ocher, .stak-ocher {
  background-color: $ocher;
  .subtitle {
    color: darken($ocher, 20%);
  }
}
.bgd-red, .stak-red {
  background-color: $red;
  .subtitle {
    color: darken($red, 20%);
  }
}
.bgd-purple, .stak-purple {
  background-color: $purple;
  .subtitle {
    color: darken($blue, 20%);
  }
}
.bgd-gray {
  background-color: $gray;
}
.bgd-light-gray {
  background-color: $light-gray;
}
.bgd-medium-gray, .stak-medium-gray {
  background-color: $medium-gray;
}
.bgd-dark-gray, .stak-dark-gray {
  background-color: $dark-gray;
}
figure {
  position: relative;
  z-index: 0;
  &.overlay {
    background-color: transparent !important;
    &:before {
      @include overlay-solid;
      z-index: -2;
    }
  }
  &.bgd-blue {
    &:before {
      background: $blue !important;
    }
  }
  &.bgd-green {
    &:before {
      background: $green !important;
    }
  }
  &.bgd-ocher {
    &:before {
      background: $ocher !important;
    }
  }
  &.bgd-red {
    &:before {
      background: $red !important;
    }
  }
  &.bgd-purple {
    &:before {
      background: $purple !important;
    }
  }
}
.skip-link.screen-reader-text {
  display: none;
}
.sticky-container {
  transition: all 0.25s;
  z-index: 999; // for dropdown pre-scroll
}
.is-at-bottom {
  top: 0 !important;
}
header.main-nav {
  background-color: rgba($white, 0.95);
  transition: all 0.25s;
  position: relative;
  min-height: 65px;
  .logo {
    width: $logo-small;
    margin: 0.5em 0;
    transition: all 0.25s;
  }
  @include breakpoint(medium) {
    .logo {
      width: $logo-medium;
    }
  }
  @include breakpoint(large) {
    // enhancements;
    //position: fixed;
    //width: 100%;
    .logo {
      width: $logo-large;
      padding: 1em;
    }
  }
  @include breakpoint(xlarge) {
    // enhancements;
    // width: 100%;
    // position: fixed;
    // z-index: 2;
    // top: 0;
  }
  // GLOBAL-MENU ///////////////////////////////////////////////
  ul.global-menu {
    vertical-align: baseline;
    width: 100%;
    justify-content: flex-end;
    text-align: right;
    > li {
      position: relative;
      text-align: left;
    }
    > li > a, > li > button {
      font-size: 0.78em;
      padding: 0 1.25em;
      color: $dark-gray;
      line-height: 3em;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 0.5s;
      position: relative;
      text-align: left;
      cursor: pointer;
      &:last-child {
        padding-right: 0;
      }
      &:hover {
        color: $secondary-color;
      }
      &:after {
        content: '';
        display: block;
        left: 0;
        height: 3px;
        width: 0;
        background: $primary-color;
        transition: width 0.5s ease, background-color 0.25s ease;
      }
      &.hover:after,
      &:hover:after {
        width: 100%;
        background: $primary-color;
      }
      &.sel {
        color: $secondary-color;
        &:after {
          width: 100%;
          background: $secondary-color;
        }
      }
    }
    @include breakpoint(large) {
      > li > a, > li > button {
        font-size: 1em;
        padding: 0 1.5em;
      }
    }
    @include breakpoint(xlarge) {
      // enhancements;
      > li > a, > li > button {
        font-size: 1em;
        padding: 0 2em;
      }
    }
  }
  .dropdown-pane {
    font-size: 1em;
    text-align: left;
    box-shadow: 0 10px 10px 0 rgba($black, .05);
    border: 1px solid $light-gray;
    a {
      line-height: 1.25em;
    }
    @include breakpoint(xlarge) {
      font-size: 1.125em;
      width: 325px;
    }
    &.mega-menu {
      width: 100% !important;
      border: none;
      background-color: transparent;
      padding: 0;
      box-shadow: none;
      &.is-open {
        left: 0 !important;
        display: block;
      }
      .content {
        background-color: $white;
        padding: 1.25em;
        border: 1px solid $light-gray;
        box-shadow: 0 10px 10px 0 rgba($black, .05);
        a {
          display: inline-block;
          line-height: 1.25em;
          &:hover {
            color: $secondary-color;
          }
        }
        .description {
          padding-top: 0.5em;
          p {
            line-height: 1.75em;
            font-size: 1.05em;
            font-family: $font-family-serif;
            font-weight: 300;
          }
          @include breakpoint(xlarge) {
            font-size: 1.125em;
            line-height: 1.35em;
          }
        }
        .nav-title {
          a {
            color: $gray;
          }
        }
        .menu .button,
        .menu a {
          line-height: 1;
          text-decoration: none;
          display: block;
          padding: 0.7rem 1rem 0.7rem 0;
          font-weight: 400;
        }
        .blocks {
          li {
            display: inline-block !important;
          }
          a {
            font-weight: normal;
            display: inline-block !important;
          }
          .menu-ico {
            max-width: 30px;
            display: inline;
            padding-right: 6px;
            line-height: 1em;
            margin: 0;
          }
        }
      }
      img {
        width: 100%;
        margin: 0.75em 0;
        display: block;
      }
    }
    .vertical.menu {
      a {
        &:hover {
          text-decoration: underline;
          color: scale-color($anchor-color, $lightness: -40%);
        }
      }
    }
  }
}
.is-stuck {
  header.main-nav {
    background-color: rgba($white, 0.95);
    height: auto;
    min-height: 65px;
    max-height: 65px;
    @include breakpoint(large) {
      // enhancements;
      .logo {
        padding: 0;
        width: $logo-medium;
      }
    }
    @include breakpoint(xlarge) {
      // enhancements;
      .logo {
        width: $logo-medium;
      }
    }
  }
}
// ancillary
.ancillary {
  text-align: right;
  display: block;
  background-color: $light-gray;
  ul.ancillary-menu {
    margin: 0 !important;
    font-size: 1em;
    padding: 0;
    list-style-type: none;
    text-transform: uppercase;
    justify-content: flex-end;
    li {
      list-style-type: none;
      padding: 0 0 0 10px;
      align-self: center;
      &.social {
        padding: 0;
        a {
          padding: 0.5rem .25em;
        }
      }
      a {
        color: $primary-color;
        padding: 0.5rem;
        &:hover {
          color: $black;
        }
        &.menu-item-81 {
          margin-right: .5rem;
        }
      }
      form {
        display: inline;
      }
      .searchform {
        input {
          padding: 1em;
        }
      }
      .icon-search {
        padding: 0.25rem 0rem;
        &:before {
          font-family: aktiv-base;
          font-size: 1.25em;
          font-style: normal;
          content: "\e801";
          color: $primary-color;
        }
      }
      .search-button {
        font-size: 1em;
        padding: 0 .5em 0 .5em;
        margin: 0 0 0 .25em;
        color: $black;
        line-height: 3em;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        text-align: left;
        display: block;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        border-left: 1px solid darken($light-gray, 10%);
        &:after {
          position: absolute;
          content: '';
          display: block;
          bottom: 0;
          left: 0;
          height: 6px;
          width: 0;
          background: $white;
          transition: all 0.25s ease;
        }
        &:hover, &:focus {
          color: $secondary-color;
        }
        &.hover {
          color: $white;
          background-color: $secondary-color;
          .icon-search {
            &:before {
              color: $white;
            }
          }
        }
        @include breakpoint(large) {
          font-size: 1em;
        }
      }
      .search-pane {
        background-color: $secondary-color;
        border: none;
        transition: all 0.25s ease;
        z-index: 1000;
        input {
          background-color: $secondary-color;
          border: 1px solid $white;
          color: $white;
          box-shadow: none;
          &::placeholder {
            color: $white;
          }
        }
      }
    }
  }
  .phone {
    font-weight: bold;
  }
  .contact {
    @include button;
    @include button-hollow;
    font-size: 1em;
    border: lighten($secondary-color, 20%) 1px solid;
    color: $secondary-color;
    transition: all 0.25s;
    margin: 0;
    a {
      color: $secondary-color;
    }
    &:hover {
      border: $secondary-color 1px solid;
    }
  }
  .x-collapse {
    .columns,
    .postfix,
    a,
    button,
    input {
      margin: 0 !important;
    }
  }
  input:focus {
    outline: none;
  }
}
// MOBILE NAVIGATION
/////////////////////////////////////
.title-bar {
  text-align: center;
  padding: 0.5em 0 0.25em;
  line-height: 1em;
  height: 4em;
  .logo {
    width: $logo-small;
    margin: 0 auto;
    padding: 0;
    .fill-black {
      fill: $graphite;
    }
  }
}
.toggle-menu {
  //margin: -2px 0.3em;
  border: none;
  background: none;
  z-index: 99999;
  transform: scale3d(0.8, 0.8, 0.8);
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
  width: 2rem;
  height: 2rem;
  display: grid;
  align-content: center;
  align-self: center;
  position: absolute;
  top: 10px;
  right: 10px;
  span {
    display: block;
    width: 2rem;
    height: 0.2rem;
    background: $secondary-color;
    border-radius: 0.5em;
    transition: 0.3s;
    position: relative;
  }
  span:before {
    display: block;
    width: 2rem;
    height: 0.2rem;
    background: $secondary-color;
    border-radius: 0.5em;
    transition: all 0.3s;
    position: absolute;
    top: 0.5em;
    left: 0;
    content: '';
    transform-origin: 0.13rem center;
  }
  span:after {
    display: block;
    width: 2rem;
    height: 0.2rem;
    background: $secondary-color;
    border-radius: 0.5em;
    transition: all 0.3s;
    position: absolute;
    top: -.5em;
    left: 0;
    content: '';
    transform-origin: 0.13rem center;
    //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
  }
  &:hover {
    span {
      background: lighten($secondary-color, 10%);
    }
    span:before {
      background: lighten($secondary-color, 10%);
    }
    span:after {
      background: lighten($secondary-color, 10%);
      //  box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    }
  }
  @include breakpoint(large) {
    display: none;
  }
}
.toggle-menu[aria-expanded="true"] {
  //background-color: $primary-color;
  span {
    background: transparent !important;
  }
  span:before {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, 45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $primary-color;
  }
  span:after {
    //box-shadow: 0 0 0 1px $white, 0 7px 0 1px $white, 0 14px 0 1px $white;
    transform: rotate3d(0, 0, 1, -45deg);
    transform-origin: 50% 50%;
    top: 0;
    background-color: $primary-color;
  }
}
.right-off-canvas-menu {
  padding-top: 3.75rem;
  .menu li a {
    display: block;
    border-bottom-color: darken($green, 10%);
    border-top: 1px solid rgba($white, 0.15);
    color: $white;
    &:hover {
      color: lighten($secondary-color, 50%);
    }
  }
  .menu li:first-of-type {
    a {
      border-top: none;
    }
  }
  .menu li:last-of-type {
    a {
      border-bottom: none;
    }
  }
  .searchform {
    input {
      padding: 1em;
    }
    .icon-search {
      padding: 0.25rem 0rem;
      &:before {
        font-family: aktiv-base;
        font-size: 1.25em;
        font-style: normal;
        content: "\e801";
      }
    }
  }
}
// GLOBAL FOOTER
/////////////////////////////////////
footer.global-footer {
  background-color: rgba($primary-color, .9);
  // background: linear-gradient(darken($primary-color, 5%) 0% , $primary-color 100%);
  padding: 2em 0 0;
  font-size: 0.9em;
  //*:not(.button){color: $gray;}
  text-align: center;
  color: lighten($primary-color, 50%);
  position: relative;
  a {
    color: $white;
  }
  .row {
    z-index: 1;
  }
  &:before {
    content: "";
    position: absolute;
    //  left: 3vw;
    //  left: 50vw;
    //  right: 50vw;
    width: 20vw;
    height: 20vw;
    top: 2em;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: block;
    background-image: url("../images/smith-logomark-solid.svg");
    background-repeat: no-repeat;
    background-size: contain;
    mix-blend-mode: soft-light;
    opacity: .15;
    z-index: 0;
  }
  .signup-inline {
    margin-top: 1em;
    .nf-cell {
      padding: 0 !important;
    }
    .nf-form-content {
      padding: .5em 0 0 0;
    }
    input[type=email] {
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      border-color: lighten($primary-color, 50%) !important;
      border-style: solid !important;
      color: $white !important;
      width: 100%;
      border-right: none !important;
      &:hover {
        color: lighten($primary-color, 10%) !important;
        background-color: darken($primary-color, 10%) !important;
      }
    }
    input[type=submit] {
      border-color: $secondary-color !important;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      border-top: 1px solid lighten($primary-color, 50%) !important;
      border-bottom: 1px solid lighten($primary-color, 50%) !important;
      border-right: 1px solid lighten($primary-color, 50%) !important;
      background-color: $secondary-color !important;
      color: $white !important;
      cursor: pointer;
      width: 100%;
      &:hover {
        color: darken($anchor-color-hover, 20%) !important;
        background-color: lighten($secondary-color, 10%) !important;
      }
    }
    @include breakpoint(medium) {
      input[type=email] {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
      }
      input[type=submit] {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }
  h5 {
    color: $white;
    font-weight: 400;
    margin-top: 1em;
  }
  .copyright {
    @include grid-column(12);
    margin: 2em auto 0 auto;
    text-align: center;
    color: lighten($primary-color, 40%);
    p {
      font-size: 0.9em;
      //color: $white;
      vertical-align: middle;
    }
  }
  .social-icons {
    font-size: 1.25em;
    a {
      margin-left: -.25em;
      margin-right: 0.5em;
      &:hover {
        color: darken($anchor-color-hover, 20%);
      }
    }
    [class^="flaticon-"]:hover:after,
    [class*="flaticon-"]:hover:before {
      color: $anchor-color-hover;
    }
  }
  .telephone {
    display: inline-block;
    margin: 0.5em 0;
    font-weight: bold;
  }
  .email {
    display: inline-block;
    margin: 0.5em 0 0 1em;
    font-weight: bold;
  }
  .button-group {
    margin-top: 2em;
    justify-content: center;
    .button {
      border: lighten($primary-color, 50%) 1px solid;
      color: $white;
      transition: all 0.25s;
      margin: 0 .25em 0 0;
      a {
        color: $secondary-color;
      }
      &:hover {
        background-color: $secondary-color;
        border: $secondary-color 1px solid;
      }
    }
  }
  .nf-field-container {
    margin-bottom: 0;
  }
  @include breakpoint(medium) {
    text-align: left;
    padding: 4em 0 2em 0;
    h5 {
      margin-top: 0;
    }
    .button-group {
      justify-content: flex-start;
    }
    //logo mark
    &:before {
      left: 3vw;
      right: unset;
    }
  }
}
.icon-callouts {
  img {
    padding-right: 0.51em;
  }
  a:hover {
    h4 {
      color: $primary-color;
    }
  }
}
.stak {
  @include stak;
  //  background-color: $white;
}
.alt-stak {
  background-color: $light-gray;
}
//CASE SLIDER
.case-slider-container {
  position: relative;
  margin-bottom: 4em;
  z-index: 10;
}
.case-slider {
  background-color: $white;
  padding-bottom: 5em;
  @include breakpoint(medium) {
    .slide-content.offset-right {
      transform: translate(5vw, 0);
    }
    .slide-content.offset-left {
      transform: translate(-5vw, 0);
    }
    figure.offset-right, figure.offset-left {
      //margin-top: -5vw;
      //margin-bottom: 5vw;
      //transform: translate(0, -5vw);
      overflow: visible;
    }
    .slick-dots {
      margin: 0 0 5vw 0;
    }
  }
  .slick-next,
  .slick-prev {
    bottom: 20px;
    left: 51%;
    background-color: $transparent;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    //border: 1px solid $primary-color;
    &:before {
      color: $primary-color;
      font-size: 26px;
      font-weight: 400;
      height: 40px;
      width: 40px;
      text-align: center;
      justify-content: center;
      margin-left: -8px;
      margin-top: 6px;
    }
  }
  .slick-prev {
    right: unset;
    left: 46%;
  }
  .slick-next {
    &:before {
      margin-left: -3px;
    }
  }
}
.module-heading.case {
  position: relative;
  background-color: $white;
  display: inline-block;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 1em;
  box-shadow: 0 -10px 10px 0 rgba($black, .05);
  margin-bottom: 0;
  h3 {
    display: inline-block;
  }
  .appenddots {
    height: 30px;
    display: inline-block;
    .slick-dots {
      top: 0;
      margin: 0;
      position: relative;
      font-size: f-header-size(medium, h3);
      li {
        button {
          font-size: f-header-size(medium, h3) !important;
          color: $primary-color;
          height: 1em;
          width: 1em;
          &:before {
            display: none;
          }
        }
        &.slick-active {
          button {
            color: $secondary-color;
          }
        }
      }
    }
  }
}
.content-slider {
  margin-bottom: 4em;
  .slick-slide {
    padding: 1em;
  }
  @include breakpoint(medium) {
    margin-bottom: 2em;
  }
}
.callout.white {
  color: $white;
  @include box-shadow;
  padding: 2em;
  @include breakpoint(large) {
    padding: 4em;
  }
  .button.hollow {
    border-color: rgba($white, .8);
    color: rgba($white, .8);
    transition: all .25s;
    &:hover {
      border-color: rgba($white, 1);
      color: rgba($white, 1);
      transform: translate(0, -4px);
    }
  }
}
header.module-heading.text-left.case-slider {
  margin: 2em 0 !important;
}
.content-slider {
  .slick-list {
    padding-bottom: 5vw;
  }
  .slick-slide {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
  figure.offset-right, figure.offset-left {
    //  margin-bottom: -5vw;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-next,
  .slick-prev {
    bottom: 50px;
    left: 51%;
    background-color: $transparent;
    z-index: 1;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    //border: 1px solid $primary-color;
    &:before {
      color: $primary-color;
      font-size: 26px;
      font-weight: 400;
      height: 40px;
      width: 40px;
      text-align: center;
      justify-content: center;
      margin-left: 0;
      margin-left: -8px;
      margin-top: 6px;
    }
  }
  .slick-prev {
    right: unset;
    left: 46%;
  }
  .slick-next {
    &:before {
      margin-left: -3px;
    }
  }
  @include breakpoint(medium) {
    .slide-content.offset-right {
      transform: translate(5vw, 0);
    }
    .slide-content.offset-left {
      transform: translate(-5vw, 0);
    }
    figure.offset-right, figure.offset-left {
      //margin-top: -5vw;
      //margin-bottom: 5vw;
      //transform: translate(0, -5vw);
      overflow: visible;
    }
    .slick-dots {
      margin: 0 0 5vw 0;
    }
  }
}
.subtitle {
  display: block;
  font-family: $font-family-serif;
  font-size: 50%;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
// HOMEPAGE
//////////////////////////////////////////////////////////////////////////
#home-top-bar {
  width: 100%;
}
iframe {
  border: none;
}
.mobile-home-hero {
  position: relative;
  &:after {
    @include overlay-home;
  }
  iframe {
    filter: blur(2px);
  }
}
.hero-mobile-container {
  text-align: left;
  color: $white;
  margin-top: -1em;
  background-color: $secondary-color;
  .hero-mobile {
    padding: 2em 1em 1em 1em;
    h1 {
      text-transform: uppercase;
    }
  }
}
.home-hero {
  //@include hero-slide; // compensates for heights of container
  margin: 0;
  backface-visibility: hidden;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  // background-color: $dark-gray;
  height: 90vh;
  .row:not(.expanded) .row {
    max-width: 75em;
    margin: 0 auto !important;
  }
  .loading {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  .vimeo-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    filter: blur(5px);
    //background-image: url("../images/video-thumb.webp");
    background-repeat: no-repeat;
    background-size: cover;
    animation-name: fadeIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: .75s;
    animation-fill-mode:forwards;
    &:after {
      @include overlay-home;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  video {
    object-fit: cover;
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 0;
    display: block;
    //margin-bottom: -6px;
    overflow: hidden;
  }
  iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .home-banner .slide .video-slide {
    background-color: #000;
    overflow: hidden;
  }
  .home-banner.loading .video-background {
    opacity: 0;
  }
  .video-background {
    position: absolute;
    top: 50%;
    left: 0;
    padding-top: 56.25%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 2s opacity ease;
    transition: 2s opacity ease;
    opacity: 1;
  }
  .video-foreground,
  .video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  @supports (-ms-ime-align:auto) {
    .video {
      background-size: 100%;
    }
  }
.video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  clip-path: polygon(0 0, 100% 0, 100% 94%, 0 98%);
  //margin-bottom: -6px;
}
@supports (-ms-ime-align:auto) {
  .video {
    background-size: 100%;
  }
}
  .slide-container {
    display: block;
  }
  .hero-caption {
    z-index: 1;
    text-align: left;
    max-width: 90vw;
    h1 {
      color: $white;
      font-weight: 800;
      margin: 0;
      word-spacing: .10em;
      letter-spacing: .05em;
      line-height: 1.25em;
    }
    h2 {
      color: $white;
      font-family: $body-font-family;
      font-weight: 300;
      margin: 0;
      line-height: normal;
      font-size: f-header-size(small, h2) - .25;
    }
    p {
      color: $white;
      font-size: $paragraph-font-size;
      margin-top: 1em;
      a {
        color: $white;
        font-weight: 600;
      }
    }
    .button {
      margin-top: 2em;
    }
  }
  @include breakpoint(medium) {
    .video {
      position: fixed;
    }
    .hero-caption {
      h1 {
        // opacity: 95%;
        //  mix-blend-mode: color-burn;
      }
      p {
        font-size: $paragraph-font-size + 0.25;
      }
      .button {
        margin-top: 2em;
      }
    }
  }
  @include breakpoint(large) {
    .hero-caption {
      h1 {
        font-size: 5vw;
      }
      h2 {
        @include header-size(h2);
      }
    }
  }
  @include breakpoint(xlarge) {
    .hero-caption {
      h1 {
        font-size: 5.5vw;
        max-width: 80%;
      }
      h2 {
        @include header-size(h2);
      }
    }
  }
}
.carousel-hero {
  margin-top: 0;
  margin-left: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  &.slick-initialized {
    opacity: 1;
    display: block;
  }
  @include hero-slide;
  // @include breakpoint(large) {
  //    .slick-next,
  //    .slick-prev {
  //       top: 48%;
  //    }
  // }
  // .slick-slide class is jQuery generated
  .slick-slide {
    @include hero-slide;
    position: relative;
    // @include breakpoint(xlarge) {
    //    clip-path: polygon(0 0, 0 97%, 48% 97%, 50% 100%, 52% 97%, 100% 97%, 100% 0);
    // }
    &:before {
      @include overlay;
    }
  }
  .slide-caption {
    @include grid-column(12);
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
    font-size: $paragraph-font-size - 0.25;
    text-align: center;
    p {
      background-color: rgba($white, 0.9);
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      padding: 1em;
      display: inline;
      border: 1px solid rgba($white, 0.8);
    }
  }
  @include breakpoint(medium) {
    .slide-caption {
      font-size: $paragraph-font-size;
    }
  }
}
//Landing pages with slide hero
.banner-slides-hero {
  margin: 0;
  backface-visibility: hidden;
  position: relative;
  .hero-caption {
    z-index: 1;
    right: 0;
    left: 0;
    width: 90%;
    position: relative;
    //h1, h2, h3 {
    //  text-shadow: 2px 2px 2px rgba(0,0,0,0.2);
    //}
    h1 {
      font-size: 1.5em;
      font-weight: 300;
      margin: 0;
    }
    h2 {
      font-family: $body-font-family;
      font-weight: 300;
      margin: 0;
    }
    h3 {
      font-size: .9em;
    }
    h1, h2, h3 {
      color: $black;
      line-height: 1.2em;
    }
    p {
      color: $black;
      font-size: $paragraph-font-size;
      margin-top: 1em;
      a {
        color: $primary-color;
      }
    }
    .button {
      @include button;
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
  @include breakpoint(medium) {
    .hero-caption {
      h1 {
        font-size: f-header-size(medium, h1)  - .5;
      }
      h3 {
        font-size: f-header-size(small, h3);
        line-height: 1.25em;
      }
      p {
        font-size: $paragraph-font-size + 0.05;
        transition: all .25s ease-in-out;
      }
      a:hover {
        p {
          color: darken($primary-color, 20%);
        }
      }
      .button {
        @include button;
        display: none;
      }
    }
    .hero-dots {
      position: absolute;
      bottom: 0;
      left: 0;
      list-style: none;
      display: block;
      height: 40px;
      width: 100%;
      .slick-dots {
        padding: 0 .5em;
        width: 90%;
        z-index: 2;
        bottom: 0;
        left: 0;
        text-align: left;
        margin: 0 auto;
        display: block;
        position: relative;
        li {
          position: relative;
          display: inline-block;
          //height: 24px;
          //width: 24px;
          margin: 0 4px;
          padding: 0;
          cursor: pointer;
          button {
            border: 0;
            background: transparent;
            display: block;
            height: 24px;
            width: 24px;
            outline: none;
            line-height: 0;
            font-size: 0;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
              outline: none;
              &:before {
                // opacity: $opacity-on-hover;
                background-color: $red;
                opacity: 90%;
              }
            }
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              content: "";
              border: 1px solid $white;
              border-radius: 100%;
              width: 18px;
              height: 18px;
              text-align: center;
              background-color: $white;
              //   opacity: $opacity-not-active;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }
          &.slick-active button:before {
            background-color: $red;
          }
        }
      }
      @include breakpoint(large) {
        .slick-dots {
          text-align: center;
          bottom: 1em;
          li {
            margin: 0 8px;
            button {
              &:before {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }
  @include breakpoint(large) {
    .hero-caption {
      // padding: 100px 0 0;
      h1 {
        font-size: f-header-size(medium, h1) + 0.75;
      }
      h2 {
        @include header-size(h2);
      }
      h3 {
        font-size: f-header-size(medium, h3) - .75;
        margin-top: 0.5em;
      }
      p {
        font-size: $paragraph-font-size + 0.5;
      }
      .button {
        display: inline-block;
      }
    }
  }
  @include breakpoint(xlarge) {
    .hero-caption {
      h1 {
        font-size: f-header-size(medium, h1) + 1.75;
        line-height: 1.15em;
      }
      h2 {
        font-size: f-header-size(medium, h2) + 1.25;
      }
      h3 {
      }
    }
  }
}
.carousel-hero {
  margin-top: 0;
  margin-left: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  &.slick-initialized {
    opacity: 1;
    display: block;
  }
  @include hero-slide;
  // .slick-slide class is jQuery generated
  .slick-slide {
    @include hero-slide;
    position: relative;
    //&:before {
    //  @include overlay;
    //}
  }
  .slick-track {
    display: flex !important;
  }
  .slide-caption {
    @include grid-column(12);
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
    font-size: $paragraph-font-size - 0.25;
    text-align: center;
    p {
      background-color: rgba($white, 0.9);
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      padding: 1em;
      display: inline;
      border: 1px solid rgba($white, 0.8);
    }
  }
  @include breakpoint(medium) {
    .slide-caption {
      font-size: $paragraph-font-size;
    }
  }
}
.carousel-hero-mobile {
  display: block;
  .slick-prev, .slick-next {
    width: 20px;
    bottom: 35%;
    &:before {
      color: $red;
      font-size: 30px;
    }
  }
  .slick-next {
    right: 4px;
  }
  &.slider {
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
  }
  img {
    position: relative;
    z-index: -2;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .banner-container {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to top, white 0%, black 50%);
      background-size: cover;
      mix-blend-mode: screen;
      z-index: -1;
    }
  }
  .hero-caption {
    background-color: $white;
    margin: -5em 2em 2em 2em;
    padding: 1em;
    box-shadow: 0 30px 30px 0 rgba($black, .15);
  }
}
.hover-callouts {
  margin: 0 auto;
  > div {
    vertical-align: bottom;
    transition: all 0.5s;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    overflow: hidden;
    background-clip: content-box !important;
    min-height: 300px;
    display: table;
    border: 4px solid $white;
    &:first-of-type {
      border-bottom: 4px solid $white;
    }
    > div {
      border-collapse: collapse;
      display: table-row;
      background-color: rgba($dark-gray, .9);
    }
    .meta {
      height: 96%;
      text-align: center;
      border: 1px solid $transparent;
      padding: 0.5em;
      margin: 0.5em 0 0;
      display: table-cell;
      vertical-align: middle;
      h3,
      h4 {
        font-weight: 400;
        color: $white;
        margin-bottom: 0;
        line-height: 1.25em;
      }
      hr {
        margin: 0.5em 0;
        border-color: teal;
      }
      p {
        color: $white;
        margin: 1em 0;
      }
    }
    @include breakpoint(medium) {
      overflow: hidden;
      background-size: cover !important;
      transition: all 0.25s;
      min-height: 400px;
      display: block;
      > div {
        background-color: rgba($dark-gray, 0.65);
        transition: all 0.5s;
        height: 100%;
        padding: 0.5em;
        display: block;
      }
      .meta {
        transform: translate(0px, 40%);
        transition: all 0.5s;
        display: block;
        h3 {
          color: $white;
        }
        p {
          color: $white;
          opacity: 0;
          transition-delay: 0;
        }
        hr {
          opacity: 0;
          transition-delay: 0;
          width: 80%;
          margin-right: auto;
          margin-left: auto;
        }
        a {
          opacity: 0;
          transition-delay: 0;
        }
      }
      &:hover {
        > div {
          background-color: rgba($dark-gray, .9);
        }
        .meta {
          transform: translate(0px, 30%);
          transition-delay: 0.25s;
          p {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.25s;
          }
          hr {
            opacity: 1;
            transition-delay: 0.25s;
          }
          a {
            opacity: 1;
            transition: all 1s;
            transition-delay: 0.5s;
          }
        }
      }
    }
    &:hover {
      background-size: 120%;
    }
  }
}
.stats {
  margin-top: 2em;
  font-family: $header-font-family;
  font-weight: 300;
  line-height: 1.25em;
  text-align: center;
  .column {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  strong {
    font-weight: 600;
    display: block;
    font-size: 1.5em;
    line-height: 1.5em;
    color: $primary-color;
  }
  @include breakpoint(large) {
    font-size: 1em;
  }
}
.testimonials {
  text-align: center;
  .row {
    align-items: center;
    justify-content: center;
  }
}
.quote-slider {
  blockquote {
    margin: 30px 0 0;
    border-left: none;
    p {
      margin: 0;
      letter-spacing: 0.1em;
      &:before {
        content: '“';
      }
      &:after {
        content: '”';
      }
    }
    footer {
      margin: 10px 0;
      font-style: italic;
      font-family: $font-family-serif;
    }
    @include breakpoint(medium) {
      p {
        font-size: 120%;
      }
    }
  }
}
.showroom-callout {
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba($white, .5);
    top: 0;
  }
}
.client-logos {
  margin: 20px 0 0;
  .ri-grid {
    margin: 0 auto;
    position: relative;
    height: auto;
  }
  .ri-grid ul {
    list-style: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  /* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
  .ri-grid ul:after,
  .ri-grid ul:before {
    content: '';
    display: table;
  }
  .ri-grid ul:after {
    clear: both;
  }
  .ri-grid ul {
    zoom: 1;
    /* For IE 6/7 (trigger hasLayout) */
  }
  .ri-grid ul li {
    perspective: 250px;
    //height: 200px!important;
    margin: 0;
    padding: 0;
    float: left;
    position: relative;
    display: block;
    overflow: hidden;
    //border: 1em solid $white;
    @include breakpoint(medium) {
      //border: 1em solid transparent;
    }
  }
  .ri-grid ul li a,
  .ri-grid ul li div {
    display: block;
    outline: none;
    position: absolute;
    z-index: 1002;
    margin: 0 auto;
    padding: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    background-size: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: content-box;
    filter: grayscale(100%) contrast(110%);
    transition: all 0.25s ease-in-out;
    &:hover {
      filter: grayscale(0%) contrast(100%);
    }
  }
  /* Grid wrapper sizes */
  .ri-grid-size-1 {
    width: 55%;
  }
  .ri-grid-size-2 {
    width: 100%;
  }
  .ri-grid-size-3 {
    width: 100%;
    //margin-top: 0;
  }
  .ri-grid-loading:after,
  .ri-grid-loading:before {
    display: none;
  }
  .ri-loading-image {
    display: none;
  }
  .ri-grid-loading .ri-loading-image {
    position: relative;
    width: 30px;
    height: 30px;
    left: 50%;
    margin: 100px 0 0 -15px;
    display: block;
  }
}
// CONTENT BUILDER
.module-heading {
  margin-bottom: 1em;
  display: block;
  h1, h3 {
    letter-spacing: .10em;
  }
}
.module-text {
}
.module-cards {
  //background-color: $light-gray;
  //margin: 2em auto;
  &.style-round {
    .card {
      margin: 0 .5em;
      padding: 0 .5em;
      box-shadow: none;
      background-color: transparent;
      border: none;
      picture, figure {
        transition: transform .3s ease;
        object-fit: cover;
        object-position: center;
        display: block;
        justify-content: center;
      }
      .article-thumb {
        width: 66%;
        max-width: 260px;
        margin: 0 auto;
        display: block;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        align-items: center;
        //aspect-ratio: 1 !important;
        display: flex;
        justify-content: center;
        @include box-shadow;
        //background-blend-mode: overlay;
        //&:before{
        //  content: "";
        //  background: linear-gradient(135deg, rgba($primary-color,1 ) 0%, rgba($secondary-color, 1) 100%);
        //  position: absolute;
        //  mix-blend-mode: normal;
        //  opacity: 1;
        //  top: 0px;
        //  bottom: 0px;
        //  left: 0px;
        //  right: 0px;
        //  z-index: 0;
        //  height: 100%;
        //  width: 100%;
        //  transform: scale(1.05);
        //}
        &:hover {
          picture, figure {
            // transform: scale(1.05);
            width: 110%;
          }
        }
      }
    }
  }
  .slick-slider {
    .slick-list {
      padding: 0 10% 0 0 !important;
    }
    .slick-next,
    .slick-prev {
      bottom: 0;
      left: 51%;
      background-color: $transparent;
      z-index: 1;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      //border: 1px solid $primary-color;
      &:before {
        color: $primary-color;
        font-size: 26px;
        font-weight: 400;
        height: 40px;
        width: 40px;
        text-align: center;
        justify-content: center;
        margin-left: 0;
        margin-left: -8px;
        margin-top: 6px;
      }
    }
    .slick-prev {
      right: unset;
      left: 46%;
    }
    .slick-next {
      &:before {
        margin-left: -3px;
      }
    }
  }
  //.column {
  //  padding: 0;
  //}
  .card-description {
    margin-bottom: 1em;
  }
  .card-section {
    background-color: $white;
    .button {
      margin-top: 1em;
    }
  }
  .article-categories {
    display: none;
  }
  .view-all-button {
    display: block;
    margin: 2em auto 0 auto;
  }
}
$masonry-css-column-gap: $global-margin;
.masonry {
  .card {
    //margin: 0 .5em 1em .5em !important;
    margin: $global-margin;
  }
}
.button-group {
  display: flex;
}
.tabs {
  background-color: transparent;
  border: none;
  display: flex;
  .tabs-title {
    background-color: transparent;
    border-bottom: 2px solid transparent;
    transition: all .25s;
    &.is-active {
      border-bottom: 2px solid $primary-color;
      > a {
        color: $secondary-color;
      }
    }
    &:hover {
      border-bottom: 2px solid $secondary-color;
      // background-color: $light-gray;
      > a {
        color: $secondary-color;
      }
    }
    > a {
      transition: all .25s;
      cursor: pointer;
      font-size: 1em;
      font-weight: 600;
      &[aria-selected='true'], &:focus, &:hover {
        background-color: transparent;
      }
    }
  }
  &.vertical {
    flex-direction: column;
    .tabs-title {
      border-right: 4px solid $light-gray;
      border-bottom: none;
      &.is-active {
        border-right: 4px solid $secondary-color;
        > a {
          color: $secondary-color;
        }
      }
      &:hover {
        border-right: 4px solid $primary-color;
      }
    }
  }
}
.tabs-content {
  .tabs-panel.is-active {
    display: block;
    animation: fadein-animation .7s ease-in-out;
    opacity: 1;
  }
}
.home-value-stak {
  background: linear-gradient(rgba($white, 0.5), rgba($white, 0.5)), url('../images/smith-logomark.svg');
  background-size: 280px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  .tabs-title > a {
    transition: font-size .1s;
    letter-spacing: .05em;
    position: relative;
    &:before {
      font-family: aktiv-base;
      font-size: 1.15em;
      font-style: normal;
      content: "\f105";
      color: $primary-color;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 1.15em;
      line-height: 1.15em;
      transition: all .25s ease-in-out;
    }
    &:hover {
      &:before {
        translate: 10px 0;
      }
    }
    &[aria-selected='true'] {
      &:before {
        color: $secondary-color;
      }
    }
  }
  @include breakpoint(medium) {
    background-position: 95% center !important;
  }
  @include breakpoint(large) {
    //padding: 6em 0;
    //margin: 4em auto;
    background-size: contain;
    .tabs-title > a {
      font-weight: 800;
      font-size: 2em;
      text-align: right;
    }
    .home-value {
      .tabs-title {
        position: relative;
      }
      p {
        font-size: 1.5em;
        font-weight: 300;
      }
    }
  }
}
.alt-stak {
  .tabs {
    .tabs-title {
      > a {
        color: $primary-color;
        border-right: 2px solid lighten($light-gray, 10%);
        &:hover {
          color: $secondary-color;
        }
      }
      &.is-active {
        border-right: 2px solid;
        background: linear-gradient(135deg, rgba($white, .15) 0%, rgba($white, 0) 75%);
        > a {
          color: $secondary-color;
        }
      }
    }
    &.vertical {
      .tabs-title {
        border-right: 2px solid $light-gray;
        &.is-active {
          border-right: 2px solid $secondary-color;
          > a {
            color: $secondary-color;
          }
        }
        &:hover {
          border-right: 2px solid $secondary-color;
        }
      }
    }
  }
  .tabs-content {
    background-color: $transparent;
  }
}
// ABOUT
//////////////////////////////////////////////////////////////////////////
.flip-shrink {
  .bgd-hero {
    transform: translate(0px, -70%);
    margin: 0 40px;
  }
  .hero-caption {
    h1, h2 {
      color: $primary-color !important;
    }
    .title {
      color: $secondary-color;
      border-bottom: 2px solid $secondary-color;
    }
  }
  .page-content {
    margin-top: -10%;
  }
  @include breakpoint(xlarge) {
    .bgd-hero {
      margin: 0 60px;
    }
  }
  .hero {
    margin-bottom: 3em;
  }
}
.casestudy {
  .bgd-hero {
    &:before {
      @include overlay;
    }
  }
  .callout {
    margin: 3em auto;
  }
}
.landing.casestudy {
  .hero {
    background-color: $white;
    overflow: hidden;
  }
}
.callout.collapse {
  padding: 0;
  img {
    object-fit: cover;
    width: 100%;
    object-position: center;
  }
}
.bgd-hero {
  background-color: $white;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  box-shadow: 0 30px 30px 0px rgba($black, .15);
  img {
    min-width: 100%;
    min-height: 100%;
    display: block;
    object-fit: cover;
  }
}
.casestudy {
  h2, h3 {
    color: $secondary-color;
  }
  p {
    color: $dark-gray;
  }
}
.landing:not(.casestudy) {
  .hero {
    &:before {
      @include overlay;
    }
  }
}
.landing, .casestudy {
  .page-content {
    transition: all .55s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }
  h2 {
    display: initial;
  }
  .hero {
    min-height: 300px;
    background-size: 120%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: $gray;
    overflow: hidden;
    .hero-caption {
      color: white;
      transform: translate(0%, -50%);
      top: 80%;
      position: absolute;
      left: 0;
      right: 0;
      max-width: 80rem;
      padding-right: 0.9375rem;
      padding-left: 0.9375rem;
      margin: 0 auto;
      h1,
      h2 {
        color: white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(small, h2);
      }
      h1 {
        font-size: f-header-size(medium, h1) - 1;
        font-weight: 500;
        line-height: 1.15em;
      }
      .title {
        font-size: f-header-size(small, h3);
        font-weight: 300;
        border-bottom: 2px solid $secondary-color;
        display: inline-block;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      background-size: cover !important;
      background-position: center bottom;
    }
    @include breakpoint(large) {
      height: 550px;
      background-size: cover !important;
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) + 0.5;
          max-width: 90%;
        }
        h2 {
          @include header-size(h2);
        }
      }
    }
    @include breakpoint(xlarge) {
      height: 600px;
    }
  }
}
// FLEXIBLE CONTENT BLOCKS
.photo-bgd-callout {
  background-position: center center !important;
  background-size: cover;
  .content {
    margin: 2em auto;
    padding: 1em;
    background-color: rgba($white, .9);
    text-align: center;
  }
  @include breakpoint(medium) {
    background-attachment: fixed !important;
    .content {
      margin: 4em auto;
      padding: 1em 2em;
      background-color: rgba($white, .9);
    }
  }
}
.cards-stak {
  .card-description {
    margin-bottom: 1em;
  }
}
// VIDEO
.flex-video {
  iframe {
    border: 0;
  }
}
.video-background {
  background-color: $light-gray;
  padding: 15px;
  margin: 0 15px !important;
}
.video-player {
  // background-color: #fff;
  padding-bottom: 5px;
  .poster {
    position: relative;
    img {
      width: 100%;
    }
  }
  .play-button {
    position: absolute;
    width: 80px;
    height: 80px;
    margin: auto;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.5s ease;
    &:hover {
      opacity: 0.5;
    }
  }
}
.split-image {
  img {
    margin-bottom: 2em;
    @include breakpoint(medium) {
      margin-bottom: 0;
    }
  }
}
h3,
h4 {
  &.underline {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background-color: $gray;
    }
  }
  line-height: 1.15em;
}
.align-middle,
.collapse,
.fill-image {
  .content {
    padding: 2em;
  }
  @include breakpoint(xlarge) {
    .content {
      padding: 4em;
    }
  }
}
.fill-image {
  .columns {
    min-height: 200px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    @include breakpoint(medium) {
      background-size: auto 100%;
    }
  }
}
.alternating-staks {
  .stak {
    &:nth-child(odd) {
      background-color: $light-gray;
    }
  }
  img.thumbnail {
    margin: 0.5em auto;
    width: 90%;
  }
}
.gallery-photo {
  &.slick-slider {
    margin-bottom: 4px !important;
  }
  > div {
    // max-width: 320px;
    overflow: hidden;
    vertical-align: middle;
    margin-bottom: 0;
    img {
      //max-width: 640px;
      width: 100%;
      vertical-align: middle;
      margin: 0 auto;
      transform: scale(1);
      transition: transform 0.25s;
    }
  }
}
.gallery-thumb {
  //max-width: 640px;
  max-height: 98px;
  background-color: $white;
  div.slick-slide {
    //min-width: 120px;
    max-height: 90px !important;
    overflow: hidden;
    vertical-align: middle;
    margin: 0;
    text-align: center;
    margin: 0 2px;
    .slick-list {
      max-height: 90px;
    }
    img {
      max-width: 120px;
      min-height: 90px;
      vertical-align: middle;
      padding: 0 4px 0 0;
      margin: 0 auto;
    }
    @include breakpoint(large) {
      img {
        max-width: 150px;
        min-height: 120px;
      }
    }
  }
  .slick-next,
  .slick-prev {
    top: 0;
    background-color: rgba($white, .9);
    z-index: 1;
    height: 100%;
    &:before {
      color: $black;
    }
  }
}
.photo-gallery {
  justify-content: center;
  text-align: center;
  .thumbnail {
    border-radius: 8px;
    border-width: 0;
    overflow: hidden;
    img {
      border-radius: 8px;
      transition: transform .1s linear, -webkit-transform .1s linear;
    }
    &:hover {
      @include box-shadow;
      img {
        transform: scale(1.05);
      }
    }
  }
}
// BLOG
/////////////////////////////////
// Article
.more-articles {
  padding-top: 2em;
  .button {
    border-radius: $button-radius;
  }
}
.article-cards, .tease-post, .module-cards, {
  .card {
    transition: all 0.5s;
    display: block;
    background-color: lighten($light-gray, 5%);
    overflow: hidden;
    border-radius: 8px;
    @include box-shadow;
    margin: .9em;
    p {
      font-size: .8em;
      margin: 1.5em auto 1.75em;
      line-height: 1.65em;
      color: darken($blue, 30%);
      font-weight: 300;
    }
    .date {
      color: $gray;
      font-size: .8em;
      display: block;
    }
    picture, figure {
      transition: transform .3s ease;
      object-fit: cover;
      object-position: center;
      display: block;
    }
    .article-thumb {
      width: 100%;
      display: block;
      position: relative;
      overflow: hidden;
      &:hover {
        picture, figure {
          transform: scale(1.05);
        }
      }
    }
    .article-categories {
      margin-bottom: 1em;
      a {
        text-transform: capitalize;
        font-size: .8em;
        color: $secondary-color;
        font-weight: 300;
        &:hover {
          color: lighten($primary-color, 8%);
        }
        &:before {
          content: ", ";
        }
      }
      a:first-of-type {
        &:before {
          content: "";
        }
      }
    }
    &:hover {
      img {
        width: 110%;
      }
    }
    h4 {
      margin-bottom: 0;
      font-weight: 600;
      font-size: f-header-size(small, h2);
      line-height: 1.25em;
    }
    .subtitle {
      font-size: 60%;
      //font-family: $font-family-serif;
      margin: .25em 0 0 0;
      line-height: 1.8em;
      //padding: 0;
    }
  }
  @include breakpoint(medium) {
    .card {
      transition: all 0.25s;
      display: block;
      margin: .9em;
      p {
        font-size: .9em;
      }
      .card-section {
        transition: all 0.5s;
        padding: 1.5em 1em;
      }
    }
    padding-bottom: 1em;
  }
}
.breadcrumbs {
  margin-top: 2em;
  vertical-align: middle;
  font-size: 0.85em;
  color: $medium-gray;;
  a {
    color: $secondary-color;
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    //font-size: 1em;
  }
  .breadcrumb_last {
    color: $medium-gray;
    cursor: not-allowed;
  }
}
.pagination-block {
  text-align: center;
  border-top: 2px solid $light-gray;
  padding-top: 2em;
  .disabled {
    color: lighten($gray, 20%);
  }
  .prev {
    float: left;
  }
  .next {
    float: right;
  }
  .current {
    font-weight: bold;
  }
}
.article {
  .hero-caption {
    h1 {
      color: $secondary-color;
    }
  }
  h2, h3, h4 {
    color: $dark-gray;
    font-weight: normal;
    b {
      font-weight: normal;
    }
  }
  p {
    a {
      color: lighten($primary-color, 20%);
      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }
  .cat-title-link {
    a {
      padding: 0 .25em;
      &:after {
        content: ","
      }
      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
  .featured-image {
    margin-bottom: 2em;
  }
  .article-pagenation {
    margin: 2em auto;
    padding: 2em;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    a {
      h4 {
        color: $dark-gray;
        //text-decoration: underline;
        font-size: .9em;
        margin-bottom: 0;
      }
      span {
        font-size: .8em;
        line-height: 1em;
        display: block;
        color: lighten($primary-color, 20%);
      }
      &:hover {
        h4, span {
          color: $secondary-color !important;
        }
      }
    }
    @include breakpoint(medium) {
      justify-content: space-between;
      .next-post {
        text-align: right;
      }
    }
  }
  aside {
    h4 {
      color: $gray;
    }
  }
}
.recent-articles-list {
  margin-bottom: 1em;
  img {
    margin-right: 1em;
  }
  .article-date {
    font-size: .8em;
    margin-bottom: 0;
  }
  .article-title {
    h6 {
      margin-top: 0;
    }
    a:hover h6 {
      //   color: lighten($primary-color, 8%);
    }
  }
}
.category-list {
  a {
    padding: 0.7rem 1rem .7rem 0;
    display: inline;
    font-size: .8em;
  }
  .article-count {
    display: inline-block;
    width: 1.5em;
    font-size: .8em;
    color: $medium-gray;
    margin-right: 1rem;
  }
}
// .comment-box {
//    margin-top: 2em;
//    padding-top: 2em;
//    border-top: 4px solid $light-gray;
// }
// .comment-form {
//    input, textarea {
//       border: none;
//       background-color: $light-gray;
//       box-shadow: none;
//       color: $gray;
//       &::placeholder {
//          color: lighten($gray, 20%);
//       }
//       &:focus::placeholder{
//          color:transparent;
//       }
//    }
//    input {
//       margin-bottom: 1em;
//    }
//    label {
//       display: none;
//    }
//    textarea {
//       min-height: 148px;
//    }
//    sup {
//       display: block;
//       font-family: $font-family-serif;
//       margin: 1em auto;
//       font-style: italic;
//       color: $gray;
//    }
//    h4 {
//       display: block;
//       font-style: normal;
//       text-transform: uppercase;
//       font-size: f-header-size(small, h6)*.8;
//       color: $primary-color;
//       font-weight: bold;
//       margin-bottom: 1em;
//    }
// }
.off-canvas {
  &.position-left {
    background-color: $white;
    .off-canvas-content {
      padding: 4em 1em;
      overflow-x: clip;
    }
  }
}
// LEADERSHIP
.subpage {
  h2 {
    display: initial;
  }
  .hero {
    &:before {
      @include overlay;
    }
    min-height: 300px;
    background-size: 100%;
    background-position: center center;
    //margin-bottom: 2.500em;
    position: relative;
    background-color: $gray;
    overflow: hidden;
    .hero-caption {
      color: white;
      transform: translate(0%, -50%);
      top: 80%;
      position: absolute;
      left: 0;
      right: 0;
      max-width: 80rem;
      padding-right: 0.9375rem;
      padding-left: 0.9375rem;
      margin: 0 auto;
      h1,
      h2 {
        color: white;
      }
      h2 {
        font-weight: 300;
        font-size: f-header-size(medium, h2) - 1;
      }
      h1 {
        font-size: f-header-size(medium, h1) - 1;
        font-weight: 500;
        line-height: 1.15em;
      }
      .title {
        font-size: f-header-size(small, h3);
        font-weight: 300;
        border-bottom: 2px solid $secondary-color;
        display: inline-block;
      }
      p {
        font-size: $paragraph-font-size;
        a {
          color: white;
          font-weight: 700;
        }
      }
    }
    @include breakpoint(medium) {
      background-size: cover !important;
    }
    @include breakpoint(large) {
      min-height: 550px;
      .hero-caption {
        h1 {
          font-size: f-header-size(medium, h1) + 0.5;
        }
        h2 {
          @include header-size(h2);
        }
      }
    }
    @include breakpoint(xlarge) {
      min-height: 600px;
    }
  }
}
// SEARCH RESULTS
.search-results, .inline-cards {
  .card {
    @include flex-grid-row($gutters: 0);
    .page-thumb {
      @include flex-grid-column($columns: 4, $gutters: 0);
    }
    .card-section {
      @include flex-grid-column()
    }
  }
}
//SITEMAP
.sitemap {
  .menu {
    @include no-bullet;
    a {
      padding: 0.7rem 0;
    }
    ul.children {
      //@include no-bullet;
    }
    li.page_item_has_children {
      > a {
        font-size: f-header-size(medium, h5);
      }
    }
  }
}
// CONTACT PAGE
.module-locations {
  h2 {
    margin-bottom: 0.5em;
    display: block;
  }
  .headquarters {
    border-right: 1px solid $gray;
  }
}
.result {
  margin: 0 0 1em 0;
  font-size: 90%;
  @include breakpoint(medium) {
    font-size: 100%;
  }
}
.bnr-googlemap {
  width: 100%;
  height: 400px;
  z-index: 99999;
  @include breakpoint(large) {
    height: 600px;
  }
}
.map-googlemap {
  width: 100%;
  height: 100% !important;
}
.nf-form-content .list-select-wrap .nf-field-element > div, .nf-form-content input:not([type=button]), .nf-form-content textarea {
  background: transparent !important;
  /* border: 1px solid #c4c4c4; */
  border-radius: 0;
  box-shadow: none;
  color: $gray !important;
  transition: all .5s;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
// ANIMATIONS
//////////////////////////////////////////////////////////////////////////
@keyframes arrow-button {
  0% {
    transform: translateZ(0) scale(1, 1.1) translate(-0%, 0);
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  50% {
    transform: translateZ(0) scale(1, 1.1) translate(101%, 0);
    opacity: 1;
  }
  51% {
    opacity: 0;
    opacity: 0;
  }
  75% {
    transform: translateZ(0) scale(1, 1.1) translate(-101%, 0);
    opacity: 0;
    animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  }
  76% {
    opacity: 1;
  }
  100% {
    transform: translateZ(0) scale(1, 1.1) translate(-0%, 0);
  }
}
.animate {
  opacity: 0;
}
.slideUp {
  animation-name: slideUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(-2%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  opacity: 0;
}
.fadeout {
  opacity: 1;
}
.fadein-animation {
  animation-name: fadein-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
@keyframes fadein-animation {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
  }
}
.fadeout-animation {
  animation-name: fadeout-animation;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes fadeout-animation {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  25% {
    transform: translateY(0%);
  }
  75% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}



.agent-wp{
  .agent-video{
    @include breakpoint(medium down) {
      margin-bottom: 40px;
    }
    .agent-video-top{}
    .agent-copy{
      margin-top: 30px;
      h3{
        color: #5D86A0;
        font-size: 24px;
        font-weight: bold;
        margin: 0 0 20px;
      }
      p{
        color: #363636;
        font-size: 16px;
        font-weight: 400;
        margin: 0 0 16px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .agent-form{
    .nf-form-layout{
      form{
        background-color: #7092AA;
        border-radius: 20px;
        padding: 40px 30px 25px 30px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
        .nf-before-form-content{
          display: none;
        }
        .nf-form-content{
          padding: 0;
          .nf-row{
            .nf-field-container{
              margin-bottom: 15px;
              .nf-field{
                input{
                  height: 55px;
                  background-color: rgba($white, 1) !important;
                  border: 1px solid #C4C4C4;
                  font-size: 16px;
                  font-weight: 400;
                  color: #363636 !important;
                  border-radius: 0;
                  outline: none;
                  &::placeholder{
                    color: #363636 !important;
                  }
                  &[type="submit"]{
                    background-color: #9EA274 !important;
                    width: 100%;
                    height: 60px;
                    border: 1px solid rgba($white, 1) !important;
                    border-radius: 37px;
                    font-size: 16px;
                    font-weight: bold;
                    color: rgba($white, 1) !important;
                    cursor: pointer;
                  }
                }
                textarea{
                  height: 200px;
                  background-color: rgba($white, 1) !important;
                  border: 1px solid #C4C4C4;
                  font-size: 16px;
                  font-weight: 400;
                  color: #363636 !important;
                  border-radius: 0;
                  outline: none;
                  &::placeholder{
                    color: #363636 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}