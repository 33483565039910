@font-face {
	font-family: 'aktiv-base';
	src: url('../fonts/aktiv-base.eot?32317666');
	src: url('../fonts/aktiv-base.eot?32317666#iefix') format('embedded-opentype'),
	url('../fonts/aktiv-base.woff?32317666') format('woff'),
	url('../fonts/aktiv-base.ttf?32317666') format('truetype'),
	url('../fonts/aktiv-base.svg?32317666#aktiv-base') format('svg');
	font-weight: normal;
	font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'aktiv-base';
    src: url('../font/aktiv-base.svg?32317666#aktiv-base') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "aktiv-base";
	font-style: normal;
	font-weight: normal;
	speak: never;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: .2em;

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-ok:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-cancel:before { content: '\e802'; } /* '' */
.icon-plus:before { content: '\e803'; } /* '' */
.icon-down-open:before { content: '\e804'; } /* '' */
.icon-left-open:before { content: '\e805'; } /* '' */
.icon-right-open:before { content: '\e806'; } /* '' */
.icon-up-open:before { content: '\e807'; } /* '' */
.icon-play-circled2:before { content: '\e808'; } /* '' */
.icon-tiktok:before { content: '\e809'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-x:before { content: "\f099";}
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-play-circled:before { content: '\f144'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-tumblr:before { content: '\f173'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-vimeo:before { content: '\f27d'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */
